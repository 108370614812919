@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@layer base {
  html {
    scroll-behavior: smooth;
    @apply antialiased;
  }
  
  body {
    @apply text-gray-900 bg-white;
    font-feature-settings: "ss01", "ss02", "cv01", "cv02";
  }

  ::selection {
    @apply bg-blue-500 text-white;
  }

  /* Improve Page speed */
  /* https://css-tricks.com/almanac/properties/c/content-visibility/ */
  main {
    content-visibility: auto;
    contain-intrinsic-size: 1000px;
  }
}

@layer components {
  .container {
    @apply px-4 mx-auto max-w-7xl sm:px-6 lg:px-8;
  }
  
  .btn {
    @apply inline-flex items-center justify-center px-6 py-3 text-base font-medium transition-all duration-200 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2;
  }
  
  .btn-primary {
    @apply text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 shadow-lg hover:shadow-xl transform hover:-translate-y-0.5;
  }

  .btn-secondary {
    @apply text-blue-600 bg-blue-50 hover:bg-blue-100 focus:ring-blue-500;
  }
  
  .heading {
    @apply text-4xl font-bold tracking-tight sm:text-5xl md:text-6xl;
  }

  .heading-gradient {
    @apply bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-400;
  }

  .card {
    @apply relative p-6 bg-white rounded-2xl shadow-xl transition-all duration-300 hover:shadow-2xl;
  }

  .input {
    @apply block w-full px-4 py-3 rounded-lg border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500;
  }

  .label {
    @apply block text-sm font-medium text-gray-700 mb-1;
  }
}

@layer utilities {
  .animation-delay-2000 {
    animation-delay: 2s;
  }
  
  .animation-delay-4000 {
    animation-delay: 4s;
  }

  .text-gradient {
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

/* Custom animations */
@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

.animate-float {
  animation: float 6s ease-in-out infinite;
}

/* Smooth scroll behavior */
:target {
  scroll-margin-top: 100px;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  @apply bg-gray-100;
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-300 rounded-full;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-400;
}

/* Remove tap highlight on mobile */
* {
  -webkit-tap-highlight-color: transparent;
}

/* Better image rendering */
img {
  image-rendering: -webkit-optimize-contrast;
}